import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import CookieConsent from 'react-cookie-consent';

const MainLayout = () => {
  return (
    <>
      <Navbar />
      <Outlet />

      <CookieConsent
        buttonText="Accept"
        style={{
          background: 'var(--color-primary)',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          padding: '1rem 2rem',
          opacity: 0.7,
          zIndex: 5000,
          fontSize: '1.15rem',
        }}
        buttonStyle={{
          color: '#fff',
          backgroundColor: 'var(--color-secondary)',
          fontSize: '1rem',
          borderRadius: '2rem',
          padding: '0.5rem 1.5rem',
        }}
        expires={150}
      >
        Our website uses cookies. Some of these cookies are essential, while
        others help us improve your experience and ensure the website runs
        smoothly. For more information, visit our Cookie Policy{' '}
        <Link to="/privacy" style={{ textDecoration: 'underline' }}>
          here
        </Link>
        .
      </CookieConsent>

      <Footer />
    </>
  );
};

export default MainLayout;
