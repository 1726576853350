import { BrowserRouter } from 'react-router-dom';
import Router from './routes';
import { ApolloProvider } from '@apollo/client';
import client from '../src/utils/apolloClient';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import ReactGA from 'react-ga4';

function App() {
  const TRACKING_ID = 'G-6YT7ET45WC';
  ReactGA.initialize(TRACKING_ID);

  return (
    <>
      <BrowserRouter>
        {/* <ApolloProvider client={client}> */}
          <ScrollToTop />
          <Router />
        {/* </ApolloProvider> */}
      </BrowserRouter>
    </>
  );
}

export default App;
