import './peoplePage.scss';

import LatestArticles from '../../components/LatestArticles/LatestArticles';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// IMAGES
import people1 from '../../assets/people/people1.jpeg'
import people2 from '../../assets/people/people2.jpeg'

const PeoplePage = () => {
  return (
    <>
    <Helmet>
      <title>People & Culture | Reborn Agency</title>
    </Helmet>
      <div className="peoplePage">
        <div className="peoplePage-title">
          <h1>
            People <br /> & Culture
          </h1>
        </div>
        <div className="peoplePage-text">
          <p>
            At reborn, we are committed to creating a welcoming and supportive
            co-working environment. We prioritise fostering collaborative
            relationships and actively promote a culture of mutual learning.
            Collaboration, innovation, and inclusivity are essential components
            of a successful and productive workspace. We strive to create a
            space where everyone feels welcomed and valued so that we may all
            benefit from our community members' unique perspectives, ideas, and
            experiences.
          </p>
        </div>
        {/* 3 SQUARES */}

        <div className="peoplePage-openApplications">
          <div className="openApplication-container hidden-m">
            <p>No open positions</p>
          </div>
          <div className="openApplication-container">
            <p>No open positions</p>
          </div>
          <div className="openApplication-container hidden-m">
            <p>No open positions</p>
          </div>
        </div>

        <div className="peoplePage-container">
          <h2>Open job applications</h2>
          <p>
            We are always open to job applications for highly motivated and
            creative individuals to join our team as public relations,
            marketing, and digital consultants. We seek individuals with
            excellent communication and interpersonal skills who can think
            outside the box and develop innovative solutions. Our ideal
            candidate would be youthful and enthusiastic, with a passion for the
            industry and a strong understanding of current public relations,
            marketing, and digital trends.
          </p>
          <p>
            If you're interested in joining our team, please don't hesitate to
            reach out.
          </p>
          <h3>We'd love to chat with you.</h3>
        </div>
      </div>

      <section>
        <div className="images" style={{marginTop: 0}}>
          <div className="image-container ">
            <img src={people1} alt="whatwedo" className="image-1" />
          </div>
          <div className="image-container ">
            <img src={people2} alt="whatwedo" className="image-2" />
          </div>
        </div>
      </section>

      <div className="peoplePage">
        <h2 className="peoplePage-subtitle">
          We want to be part of your <br /> successful marketing career.
        </h2>
        <div className="peoplePage-container">
          <h2 className="peoplePage-subtitle-r">Internship at reborn</h2>
          <p className="peoplePage-text-r">
            If you're eager to gain more experience at reborn and can describe
            yourself as a confident, hardworking, and detail-oriented individual
            - you're a step closer to being part of reborn as an <b>intern</b>.
            Your internship can be based on three areas:
          </p>
        </div>
        {/* pravokutnici, crni, sivi, crni - Digital reintern, PR reintern, Marketing & Events reintern */}
        <div className="peoplePage-openApplications">
          <div className="openApplication-container">
            <p>Digital intern</p>
          </div>
          <div className="openApplication-container">
            <p>PR intern</p>
          </div>
          <div className="openApplication-container">
            {' '}
            <p>Marketing & Events intern</p>
          </div>
        </div>
      </div>

      <div className="peoplePage-contactBtn">
        <Link to="/contact">
          <h1>
            Contact<span> us</span>
          </h1>
        </Link>
      </div>
      <div className="peoplePage">
        <LatestArticles />
      </div>
    </>
  );
};

export default PeoplePage;
