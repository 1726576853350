import './whatWeDoPage.scss';
import { GrNext } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { motion, useAnimation } from 'framer-motion';
// import { useInView } from 'react-intersection-observer';
import { useInView } from 'framer-motion';

import Allies from '../../components/Allies/Allies';
import Clients from '../../components/Clients/Clients';
import mintLime from '../../assets/partners/mint-lime-logo.png';
import redLight from '../../assets/partners/allies-red.png';
import d4v from '../../assets/partners/D4V-Logo.png';
import mediahint from '../../assets/partners/mediahint_transp_white_50.png';

import wespaLogo from '../../assets/clients/wespa-logo.png';
import grabAhome from '../../assets/clients/grabAhome.png';
import nielsen from '../../assets/clients/nielsen.png';
import oazaMira from '../../assets/clients/oazaMira.png';
import missturism from '../../assets/clients/miss-tourism_logo.png';
import tzzz from '../../assets/clients/tzzz.png';
import gruntek from '../../assets/clients/Logo_gruntek.png';
import valamar from '../../assets/clients/valamar.png';

// IMAGES
import wwd1 from '../../assets/wwd/wwd1.jpeg';
import wwd2 from '../../assets/wwd/wwd2.jpeg';
import wwd3 from '../../assets/wwd/wwd3.jpeg';
import wwd4 from '../../assets/wwd/wwd4.jpeg';
import wwd5 from '../../assets/wwd/wwd5.jpeg';
import wwd6 from '../../assets/wwd/wwd6.jpeg';
import { useEffect } from 'react';
import { useRef } from 'react';

function Section({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div ref={ref}>
      <div
        style={{
          transform: isInView ? 'none' : 'translateX(-100vw)',
          opacity: isInView ? 1 : 0,
          transition: 'all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
        }}
        className="services-container"
      >
        {children}
      </div>
    </div>
  );
}

const WhatWeDo = () => {
  // const { ref, inView } = useInView({
  //   threshold: 0.3,
  //   triggerOnce: true,
  // });
  // const animation = useAnimation();

  // useEffect(() => {
  //   if (inView) {
  //     animation.start({
  // x: 0,
  // transition: {
  //   type: 'spring',
  //   duration: 1,
  //   bounce: 0.2,
  // },
  //     });
  //   }
  //   if (!inView) {
  //     animation.start({
  //       x: '-100vw',
  //     });
  //   }
  //   console.log('inView = ', inView);
  // }, [inView, animation]);

  const variants = {
    visible: (custom) => ({
      // opacity: 0.5,
      // transition: { delay: custom * 0.2 }
      x: 0,
      transition: {
        type: 'spring',
        duration: 1,
        bounce: 0.2,
      },
    }),
  };

  return (
    <>
      <Helmet>
        <title>What we do? | Reborn Agency</title>
      </Helmet>
      <div className="wwd-page-container">
        {/* <section> */}
        <div className="hero">
          <div className="top">
            <h1>What we</h1>
          </div>
          <div className="bottom">
            <h1>do?</h1>
            <p>
              From the kick-off event for a new brand on the market to the most
              complicated communication strategy - we're onto it. So check out
              which services you find most suitable for your brand, and remember
              to call us.
            </p>
          </div>
        </div>
        {/* </section> */}
        {/* <section> */}
        <div className="image">
          <img src={wwd1} alt="people" />
        </div>
        {/* </section> */}
        <section>
          <div
            className="our-services"
            // ref={ref}
          >
            <h1>Our Services</h1>
            {/* <motion.div
              className="services-container"
            > */}
            <Section>
              <div className="container bg-dark">
                <h2>
                  Public <br /> Relations
                </h2>
              </div>
              <p>
                Public relations involves so much more than just sending out a
                press release. It encompasses creating a positive relationship
                between an organisation and its public, including the media,
                customers, employees, investors, and other stakeholders. This
                can be achieved through a variety of tactics and different
                strategies. Each strategy or tactic is unique and offers various
                benefits for a brand.
              </p>
              {/* </motion.div> */}
            </Section>
            <Section>
              {/* <motion.div
              className="services-container sc-reverse"
              // animate={animation}
            > */}
              <p>
                Creating engaging and creative campaigns or content to help you
                reach your target audience is one of our specialties. We have
                experience developing content for social media, email campaigns,
                and web pages to ensure that the right people see your message.
                But, if we talk about marketing campaigns, we're tied to your
                budget and delivering the best possible return on investment.
              </p>
              <div className="container bg-light">
                <h2>
                  Marketing <br /> Communications
                </h2>
              </div>
              {/* </motion.div> */}
            </Section>
          </div>
        </section>
      </div>
      <section>
        <div className="images">
          <div className="image-container ">
            <img src={wwd2} alt="whatwedo" className="image-1" />
          </div>
          <div className="image-container ">
            <img src={wwd3} alt="whatwedo" className="image-2" />
          </div>
        </div>
      </section>
      <div className="wwd-page-container">
        <section>
          <div className="our-services">
            {/* <div className="services-container"> */}
            <Section>
              <div className="container bg-dark">
                <h2>
                  Digital Strategy, <br /> Social & Content
                </h2>
              </div>
              <p>
                We have extensive experience helping our clients create
                effective digital strategies that maximise their reach, engage
                their target audiences, and drive conversions. All that comes
                with developing social media campaigns and influencer marketing
                strategies tailored to clients' goals.
              </p>
            </Section>
            {/* </div> */}
            <div className="services-container sc-reverse">
              <p>
                We make creative and innovative branding solutions tailored to
                your unique needs and budget with the best design partners. We
                can discuss brand identity for a new brand, rebranding,
                positioning, and many more. Firstly, we need to feel the
                connection with the brand's core values and messaging; then
                comes the time for the logo, colours, general application, and
                brand feeling.
              </p>
              <div className="container bg-light">
                <h2>
                  Creative solutions <br /> & Branding
                </h2>
              </div>
            </div>
            <div className="services-container">
              <div className="container bg-dark">
                <h2>
                  Events <br /> & Production
                </h2>
              </div>
              <p>
                From planning to execution, we're masters in creating a
                successful event that your guests will remember. Online, hybrid,
                or offline - you name it. We're here to help you design the
                perfect program, choose the right venue, create a stylish décor,
                and find the best entertainment options. Event production comes
                with the following. So, we're free to suggest and plan lighting,
                sound, and other technical aspects for your event.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="title">
            <h1>
              With our partners, <br />
              we do the impossible.
            </h1>
          </div>
        </section>
      </div>

      <section>
        <div className="images">
          <div className="img-container-s ">
            <img src={wwd4} alt="whatwedo" className="image-1 img-s" />
          </div>
          <div className="img-container-l ">
            <img src={wwd6} alt="whatwedo" className="image-3 img-s" />
          </div>
          <div className="img-container-s ">
            <img src={wwd5} alt="whatwedo" className="image-2 img-s" />
          </div>
        </div>
      </section>

      <div className="wwd-page-container">
        <section>
          <div className="our-services">
            <div className="services-container sc-reverse">
              <p>
                With our partners, we offer top-notch video & photo services to
                help you capture any moment you want to remember. Our external
                team of experienced professionals will work with you to get the
                best possible results. From corporate events to political
                campaigns to social media materials, we can provide you with the
                perfect imagery & video to preserve your memories.
              </p>
              <div className="container bg-light">
                <h2>
                  Video <br /> & Photo
                </h2>
              </div>
            </div>
            <div className="services-container">
              <div className="container bg-dark">
                <h2>
                  Digital solutions, <br /> web & apps
                </h2>
              </div>
              <p>
                In partnership with experienced developers from several agencies
                specializing in creating interactive and user-friendly digital
                solutions, we will make a website and app tailored to clients'
                needs.
              </p>
            </div>
            <div className="services-container sc-reverse">
              <p>
                Tailored media and public relations training services are
                something that we love to organise for our clients. Our team of
                external professionals is experienced in providing personalised
                instruction tailored to our client's specific needs. We can
                offer comprehensive training in media relations, public
                relations, and digital communications.
              </p>
              <div className="container bg-light">
                <h2>
                  Educations <br /> & Trainings
                </h2>
              </div>
            </div>
          </div>
        </section>
        <div className="partners">
          <div className="partners-row">
            <div className="logo ">
              <img src={mintLime} alt="mintlime" />
            </div>
            <div className="logo ">
              <img src={redLight} alt="redlight" />
            </div>
          </div>
          <div className="partners-row">
            <div className="logo">
              <img src={d4v} alt="division4vision" className="logo-padding" />
            </div>
            <div className="logo">
              <img src={mediahint} alt="mediahint" className="logo-padding" />
            </div>
          </div>
        </div>
      </div>
      {/* <section> */}
      <div className="wwd-line-container">
        <Link to="/latest">
          <h1>
            Experience <span> our work</span>
            <GrNext />
          </h1>
        </Link>
      </div>
      {/* </section> */}

      <section>
        <div className="wwd-clients-container">
          <div className="clients-column m-hidden">
            <div className="client-row-xl  m-hidden">
              <img src={gruntek} alt="Gruntek" />
            </div>
          </div>
          <div className="clients-column">
            <div className="client-row-s row-container m-hidden">
              <img src={valamar} alt="Valamar" />
            </div>
            <div className="client-row-l row-container m-hidden">
              <img src={oazaMira} alt="Oaza Mira" />
            </div>
          </div>
          <div className="clients-column">
            <div className="client-row-s row-container m-hidden">
              <img src={nielsen} alt="Nielsen" />
            </div>
            <div className="client-row-l row-container  m-hidden">
              <img src={tzzz} alt="TZZZ" />
            </div>
          </div>
          <div className="clients-column ">
            <div className="client-row-m row-container ">
              <img src={oazaMira} alt="Oaza Mira" />
            </div>
            <div className="client-row-m row-container ">
              <img src={grabAhome} alt="grabAHome" />
            </div>
          </div>
          <div className="clients-column ">
            <div className="client-row-s row-container ">
              <img src={missturism} alt="Wespa" />
            </div>
            <div className="client-row-l row-container">
              <img src={nielsen} alt="Nielsen" />
            </div>
          </div>
        </div>
        {/* <Clients /> */}
      </section>

      <section>
        <div className="wwd-contact">
          <div className="button">
            <Link to="/contact" className="write-us-btn">
              Write to us.
            </Link>
          </div>
          <div className="text">
            <h1>
              If we could know you're here at the moment, we would message you.
            </h1>
            <h1>
              As we cannot know, <span>this one is on you.</span>{' '}
            </h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatWeDo;
