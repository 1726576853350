import './articlePage.scss';
import { useQuery, gql } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { IoIosArrowBack } from 'react-icons/io';
import LatestArticles from '../../components/LatestArticles/LatestArticles';
import { Helmet } from 'react-helmet';

const ARTICLE_BY_SLUG = gql`
  query GetArticle($slug: String!) {
    articles(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          title
          body
          slug
          thumbnail
          date
          category {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const ArticlePage = () => {
  const { slug } = useParams();
  const { loading, error, data } = useQuery(ARTICLE_BY_SLUG, {
    variables: { slug: slug },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <>
      <Helmet>
        <title>{data.articles.data[0].attributes.title} | Reborn Agency</title>
      </Helmet>
      <div className="articlePage">
        <div className="articlePage-navigation">
          <Link to="/latest" className="back-button">
            <IoIosArrowBack /> <p>go back to latest</p>
          </Link>
        </div>
        <div className="articlePage-header">
          <div className="header-image">
            <img src={`${data.articles.data[0].attributes.thumbnail}`} alt="" />
          </div>
          <div className="header-title">
            <h1>{data.articles.data[0].attributes.title}</h1>
            <div className="header-subtitle">
              <h5>{data.articles.data[0].attributes.date}</h5>
              <span>|</span>
              <h5>
                {data.articles.data[0].attributes.category.data.attributes.name}
              </h5>
            </div>
          </div>
        </div>
        <div className="articlePage-content">
          <ReactMarkdown>{data.articles.data[0].attributes.body}</ReactMarkdown>
        </div>
        <div className="articlePage-footer">
          <div className="articlePage-footer-btn">
            <Link to="/latest">Latest from reborn</Link>
          </div>
          <LatestArticles />
        </div>
      </div>
    </>
  );
};

export default ArticlePage;
