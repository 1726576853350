import '../ArticlePage/articlePage.scss';

import { Link } from 'react-router-dom';

import { IoIosArrowBack } from 'react-icons/io';
import LatestArticles from '../../components/LatestArticles/LatestArticles';
import { Helmet } from 'react-helmet';



const Article1 = () => {
 

  return (
    <>
      <Helmet>
        <title>Take off with reborn: how brands start to fell in love (with us) | Reborn Agency</title>
      </Helmet>
      <div className="articlePage">
        <div className="articlePage-navigation">
          <Link to="/latest" className="back-button">
            <IoIosArrowBack /> <p>go back to latest</p>
          </Link>
        </div>
        <div className="articlePage-header">
          <div className="header-image">
            <img src='https://reborn-s3.s3.eu-central-1.amazonaws.com/article1_84413c98e0.webp' alt="" />
          </div>
          <div className="header-title">
            <h1>Take off with reborn: how brands start to fell in love (with us)</h1>
            <div className="header-subtitle">
              <h5>January 24, 2023</h5>
              <span>|</span>
              <h5>
                Blog
              </h5>
            </div>
          </div>
        </div>
        <div className="articlePage-content">
         <p>Working with a marketing communications agency is a great way to build and strengthen your brand's presence by reaching new audiences, gaining their trust, and creating a predefined brand perception. Building relationships with key influencers, media, and other stakeholders are also part of an agency's job.</p>
         <p>Cooperation between an agency and a client typically starts with discussing the client's overall communications and/& marketing strategy and objectives. Here is a description of a taking-off with a new reborn client to help you understand the whole process in three steps.</p>
         <img src="https://reborn-s3.s3.eu-central-1.amazonaws.com/article14_29a3207998.jpeg" alt="" />
         <h3>1. Pitch & Meetings</h3>
         <p>It's always a pleasure to meet new clients! At our first meeting, whether through a pitch or direct contact, we like to get to know each other. Eventually, after that step, is a meeting where we discuss the goals, expectations, and overall plans for the brand or a project.</p>
         <h3>2. Little bit more calls and meetings</h3>
         <p>We'll discuss the project timeline, deliverables, and any potential challenges we may face, as well as the budget. Then, we'll discuss creative ideas and strategies to help clients reach their goals. Finally, we'll review our contract and answer any client questions.</p>
         <img src="https://reborn-s3.s3.eu-central-1.amazonaws.com/article13_0e09b587a9.jpeg" alt="" />
         <h3>3. Take off workshop</h3>
         <p>Take off workshop, as we call it, is the first client-agency workshop after signing a contract that takes half a day at our or your HQ. It is organised to learn more profoundly about the company or a brand while working together to gain more insights and details. Don't worry; there will be food.</p>
         <p>After the whole strategy or plan is developed, the agency and client will work closely together to execute the strategy or a project plan, with the agency providing guidance and support along the way. The result is an effective communications and/or marketing plan that guide clients to their goals. The onboarding process can be faster when events and time demand so.</p>
        </div>
        <div className="articlePage-footer">
          <div className="articlePage-footer-btn">
            <Link to="/latest">Latest from reborn</Link>
          </div>
          <LatestArticles />
        </div>
      </div>
    </>
  );
};

export default Article1;
