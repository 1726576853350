import './whatWeDo.scss';
import { HiPlus } from 'react-icons/hi';
import { GrNext } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import PopUpWWD from '../PopUpWWD/PopUpWWD';

const WhatWeDo = () => {
  const [openModal, setOpenModal] = useState({ show: false, modalId: 0 });

  const handleOpenModal = (id) => {
    setOpenModal({ show: true, modalId: id });
  };

  const handleCloseModal = () => {
    setOpenModal({ show: false });
  };

  return (
    <>
      <div className="wwd-container">
        <div className="wwd-grid">
          
          <div className="title-1">
            <p>What we</p>{' '}
          </div>
          <div className="title-2">
            <p>do?</p>{' '}
          </div>

          <div className="grid-container-black one">
            <button
              onClick={() => handleOpenModal(1)}
              className="grid-container-btn"
            >
              <HiPlus />
            </button>
            <h2>
              Public <br /> Relations
            </h2>
            {openModal.show && openModal.modalId === 1 && (
              <PopUpWWD closeModal={handleCloseModal} index={0} />
            )}
          </div>
          <div className="grid-container-white two">
            <button
              onClick={() => handleOpenModal(4)}
              className="grid-container-btn"
            >
              <HiPlus />
            </button>
            <h2>Creative solutions & Branding</h2>
            {openModal.show && openModal.modalId === 4 && (
              <PopUpWWD closeModal={handleCloseModal} index={3} />
            )}
          </div>
          <div className="grid-container-black three mobile-container-black ">
            <button
              onClick={() => handleOpenModal(3)}
              className="grid-container-btn "
            >
              <HiPlus />
            </button>
            <h2>Digital Strategy, Social & Content</h2>
            {openModal.show && openModal.modalId === 3 && (
              <PopUpWWD closeModal={handleCloseModal} index={2} />
            )}
          </div>
          <div className="grid-container-black four mobile-container-white">
            <button
              onClick={() => handleOpenModal(5)}
              className="grid-container-btn"
            >
              <HiPlus />
            </button>
            <h2>
              Events <br /> & Production
            </h2>
            {openModal.show && openModal.modalId === 5 && (
              <PopUpWWD closeModal={handleCloseModal} index={4} />
            )}
          </div>
          <div className="grid-container-white five mobile-container-black">
            <button
              onClick={() => handleOpenModal(2)}
              className="grid-container-btn"
            >
              <HiPlus />
            </button>
            <h2>Marketing Communications</h2>
            {openModal.show && openModal.modalId === 2 && (
              <PopUpWWD closeModal={handleCloseModal} index={1} />
            )}
          </div>
          <div className="grid-container-black six mobile-container-white">
            <button
              onClick={() => handleOpenModal(7)}
              className="grid-container-btn"
            >
              <HiPlus />
            </button>
            <h2>Digital solutions, web & apps</h2>
            {openModal.show && openModal.modalId === 7 && (
              <PopUpWWD closeModal={handleCloseModal} index={6} />
            )}
          </div>
          <div className="grid-container-white seven mobile-container-black">
            <button
              onClick={() => handleOpenModal(6)}
              className="grid-container-btn"
            >
              <HiPlus />
            </button>
            <h2>
              Video <br /> & Photo
            </h2>
            {openModal.show && openModal.modalId === 6 && (
              <PopUpWWD closeModal={handleCloseModal} index={5} />
            )}
          </div>
          <Link to="what-we-do" className="grid-container-main eight" >
            <h2>EXPERIENCE OUR WORK</h2>
            <button>
              <GrNext />
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default WhatWeDo;
