import './ourStoryPage.scss';
import { GrNext } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import LatestArticles from '../../components/LatestArticles/LatestArticles';

import story1 from '../../assets/story/story1.jpeg';
import story2 from '../../assets/story/story2.jpeg';
import story3 from '../../assets/images/bg4.jpeg';
import story4 from '../../assets/images/bg2.jpeg';
import story5 from '../../assets/images/bg8.jpeg';

const OurStoryPage = () => {
  return (
    <>
      <Helmet>
        <title>Our Story | Reborn Agency</title>
      </Helmet>
      <div className="os-page-container">
        <section>
          <div className="hero">
            <div className="images">
              <img src={story2} alt="hero1" className="hero1" />
              <img src={story1} alt="hero2" className="hero2" />
              <img src={story3} alt="hero3" className="hero3" />
            </div>
            <div className="title">
              <h1>
                Our <br />
                Story
              </h1>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="text">
              <p>
                We could talk about how we got here and where we are now. But
                instead, we will talk about what we are now and what we are
                achieving with our clients. We love to say we're an agency, but
                nothing like it. Not just because of our philosophy of work and
                client relations but our commitment to creating innovative and
                creative marketing solutions that make a real difference.
              </p>
            </div>
            <div className="image">
              <img src={story4} alt="section1" />
            </div>
          </div>
          <div className="container os-reverse">
            <div className="image">
              <img src={story5} alt="section1" />
            </div>
            <div className="text">
              <p>
                Suppose you're looking for an agency to send your press releases
                weekly. Great, but we don't see ourselves like that. If you're looking for a new marketing and
                communications partner for your brand - you're on the right
                site. That's us.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="guidlines">
            <h1 className="guidlines-title">
              Our <br />
              guidlines
            </h1>

            <div className="guidlines-container">
              <div className="text">
                <h1 style={{ color: '#9b9b9d' }}>Growth</h1>
                <p className="guidlines-text">
                  We understand the importance of company growth, and we'd love
                  to help in the way we are the best. Our team specialises in
                  creating comprehensive marketing & communication strategies
                  that are tailored to each client's needs.
                </p>
              </div>
              <div className="text">
                <h1 style={{ color: '#6e6e70' }}>Always on</h1>
                <p className="guidlines-text">
                  At reborn, we understand the importance of having a team
                  always available to our clients. We are committed to providing
                  quick and reliable service, and our crew is always ready to
                  answer any questions or provide assistance when needed. In
                  addition, our experience and expertise ensure that our clients
                  get the best solutions for their communication needs.
                </p>
              </div>
            </div>

            <div className="guidlines-container">
              <div className="text">
                <h1 style={{ color: '#5b5b5d' }}>Agility</h1>
                <p className="guidlines-text">
                  We know how important it is to stay up to date with the latest
                  trends and technologies and to keep up with the ever-changing
                  digital landscape. Our team is here to help you stay ahead of
                  the curve by providing you with the best marketing strategies
                  and resources. With an agile approach, we can quickly adapt to
                  changes in the market and adjust strategy as needed.
                </p>
              </div>
              <div className="text">
                <h1 style={{ color: '#3d3d3e' }}>Experience</h1>
                <p className="guidlines-text">
                  We have such a solid foundation to build upon. We are a young
                  team with extensive communications and marketing experience in
                  various industries. As you will work with us, remember to
                  leverage each team member's unique skills and creativity.
                  Collaboration is vital to creating successful and impactful
                  projects for your brand.
                </p>
              </div>
            </div>
          </div>

          <div className="title-container">
            <h2>We know our clients by the way of thinking.</h2>
          </div>
        </section>
      </div>
      <section>
        <div className="line-container">
          <Link to="/latest">
            <h1>
              Experience <span> our work</span>
              <GrNext />
            </h1>
          </Link>
        </div>
      </section>
      <LatestArticles />
    </>
  );
};

export default OurStoryPage;
