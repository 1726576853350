import './subscriptionModelPage.scss';

import { Helmet } from 'react-helmet';
import { useState } from 'react';
import PopUpPackagePlus from '../../components/PopUpPackages/PopUpPackagePlus';
import PopUpPackageBasic from '../../components/PopUpPackages/PopUpPackageBasic';
import SubscriptionForm from '../../components/SubscriptionForm/SubscriptionForm';
import LatestArticles from '../../components/LatestArticles/LatestArticles';

// IMAGES
import sub1 from '../../assets/subscription/sub1.jpeg'
import sub2 from '../../assets/subscription/sub2.jpeg'
import sub3 from '../../assets/subscription/sub3.jpeg'
import sub4 from '../../assets/subscription/sub4.jpeg'

const SubscriptionModelPage = () => {
  const [openModal, setOpenModal] = useState({ show: false, modalId: 0 });

  const handleOpenModal = (id) => {
    setOpenModal({ show: true, modalId: id });
  };

  const handleCloseModal = () => {
    setOpenModal({ show: false });
  };

  return (
    <>
    <Helmet>
      <title>Subscription model | Reborn Agency</title>
    </Helmet>
      <div className="sm-page-container">
        <div className="title">
          <span>
            <h1>Subscription</h1>
          </span>
          <span>
            <h3>soon</h3>
            <h1>model</h1>
          </span>
        </div>

        <div className="container">
          <div className="text">
            <p>
              Now you can subscribe and pay for marketing services like you're
              using an online streaming every day, only with a reborn
              subscription. Subscription is a great way for small businesses to
              get in front of potential customers, while services included in
              two different models vary depending on the volume of services.
              Signing up for one of our subscription models is a fast and easy
              way to ensure needful marketing services for any business.
            </p>
            <p>
              <b>So, why not give it a try today?</b>
            </p>
          </div>
          <div className="image">
            <img src={sub1} alt="image1" />
          </div>
        </div>

        <div className="models">
          <div className="models-title">
            <h1>
              Different models, <br />
              different needs.
            </h1>
          </div>
          <div className="models-container">
            <div className="sm-model">
              <div className="model-container">
                <h1>basic</h1>
                <h3>from 540 euros</h3>
              </div>
              <div className="text">
                <ul>
                  <li>
                    <span>1</span> Social Media Management & set-up
                  </li>
                  <li>
                    <span>2</span> Basic Marketing & Communications support
                  </li>
                  <li>
                    <span>3</span>Monthly reports
                  </li>
                  <button onClick={() => handleOpenModal(1)}>Read more</button>
                </ul>
              </div>

              {openModal.show && openModal.modalId === 1 && (
                <PopUpPackageBasic closeModal={handleCloseModal} />
              )}
            </div>
            <div className="plus-model">
              <div className="model-container">
                <h1>plus</h1>
                <h3>from 900 euros</h3>
              </div>
              <div className="text">
                <ul>
                  <li>
                    <span>1</span> Social Media Management & set-up
                  </li>
                  <li>
                    <span>2</span>Marketing & Communications materials{' '}
                  </li>
                  <li>
                    <span>3</span>Newsletter marketing
                  </li>
                  <li>
                    <span>4</span>Blog post
                  </li>
                  <li>
                    <span>5</span>Public Relations article
                  </li>
                  <li>
                    <span>6</span>Monthly reports
                  </li>
                  <button onClick={() => handleOpenModal(2)}>Read more</button>
                </ul>
              </div>

              {openModal.show && openModal.modalId === 2 && (
                <PopUpPackagePlus closeModal={handleCloseModal} />
              )}
            </div>
          </div>
        </div>
        <div className="cs-container">
          <div className="image">
            <img src={sub2} alt="case-study" />
          </div>
          <div className="cs-text">
            <div className="cs-title">
              <h2>Case study</h2>
            </div>
            <p>
              See how it can be applicable to your business trough a case study.
            </p>
          </div>
        </div>
        <div className="text-container">
          <div className="text-title">
            <h1>
              Choose the one <br />
              that suits you.
            </h1>
          </div>
          <div className="text-p">
            <p>
              Both models combine necessary services for any small business,
              while Plus model goes beyond essential social media services and
              includes various marketing services. Moreover, small business
              owners can change their subscription model every month.* By
              choosing Basic or Plus models, you can forget classic contracts,
              monthly accounting challenges, additional fees, and many more.
            </p>
            <p>*before the subscription period starts</p>
          </div>
        </div>
        <div className="images">
          <div className="left">
            <img src={sub3} alt="left" />
          </div>
          <div className="right">
            <img src={sub4} alt="right" />
          </div>
        </div>
      </div>
      <div className="subscribe-container">
        <button onClick={() => handleOpenModal(3)} className="subscribe-btn">
          Subscribe
        </button>
        <h2>available soon</h2>

        {openModal.show && openModal.modalId === 3 && (
          <SubscriptionForm closeModal={handleCloseModal} />
        )}
      </div>
      {/* <div className="reviews"></div> */}
      <LatestArticles/>
    </>
  );
};

export default SubscriptionModelPage;
