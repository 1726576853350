import "../ArticlePage/articlePage.scss";
import { Link } from "react-router-dom";

import { IoIosArrowBack } from "react-icons/io";
import LatestArticles from "../../components/LatestArticles/LatestArticles";
import { Helmet } from "react-helmet";

const Article2 = () => {
  return (
    <>
      <Helmet>
        <title>
          We found our place: it's called Wespa, and it rocks | Reborn Agency
        </title>
      </Helmet>
      <div className="articlePage">
        <div className="articlePage-navigation">
          <Link to="/latest" className="back-button">
            <IoIosArrowBack /> <p>go back to latest</p>
          </Link>
        </div>
        <div className="articlePage-header">
          <div className="header-image">
            <img
              src="https://reborn-s3.s3.eu-central-1.amazonaws.com/article31_28f938dd24.webp"
              alt=""
            />
          </div>
          <div className="header-title">
            <h1>We found our place: it's called Wespa, and it rocks</h1>
            <div className="header-subtitle">
              <h5>January 22nd, 2023</h5>
              <span>|</span>
              <h5>Blog</h5>
            </div>
          </div>
        </div>
        <div className="articlePage-content">
          <p>
            Reborn Communications Ltd. is thrilled to be part of the fantastic
            business and start-up community at the Wespa Business & Lounge
            co-working spaces at the Green Gold Center at Radnička street 52 in
            Zagreb.
          </p>
          <img
            src="https://reborn-s3.s3.eu-central-1.amazonaws.com/article32_a12edff507.webp"
            alt=""
          />
          <h4>Foto: Marko Tomić / Kingdom agencija</h4>
          <p>
            The new co-working hub at the very business center of the City of
            Zagreb is our new Headquarters from January 2023. We cannot hide our
            excitement about our new place's possibilities and feel the
            inspiration of a start-up spirit.
          </p>
          <img
            src="https://reborn-s3.s3.eu-central-1.amazonaws.com/article33_77e479d284.webp"
            alt=""
          />
          <h4>Foto: Marko Tomić / Kingdom agencija</h4>
          <img
            src="https://reborn-s3.s3.eu-central-1.amazonaws.com/article34_ba17f1ceff.webp"
            alt=""
          />
          <h4>Foto: Marko Tomić / Kingdom agencija</h4>
          <p>
            There are so many possibilities and opportunities; we can't wait to
            explore them all, make the most of this new journey, and enjoy every
            moment. We look forward to connecting with other like-minded
            individuals, companies, and start-ups and finding new and innovative
            ways to collaborate and grow together with them. We are so excited
            to see what our new place will bring because this represents an
            opportunity for us to further our success!
          </p>
        </div>
        <div className="articlePage-footer">
          <div className="articlePage-footer-btn">
            <Link to="/latest">Latest from reborn</Link>
          </div>
          <LatestArticles />
        </div>
      </div>
    </>
  );
};

export default Article2;
