import './people.scss'

import ivanBiosic from '../../assets/people/IMG_7999.jpeg'

const People = () => {
  return (
    <div className='people-container'>
      <div className="image-container">
        <img src={ivanBiosic} alt="Ivan Biosic" />
      </div>
      <div className="text-container">
        <p >
        "We love to work with clients oriented to discovering the brand's full potential. Extensive & diverse experiences drive us to solve any challenge with the best possible outcome. We love clients who see us as partners while working together to overcome the top concerns of a brand."
        </p>
        <p >
        - Ivan Biošić, Founder & Chief Experience Officer
        </p>
      </div>
    </div>
  )
}

export default People