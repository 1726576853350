import './footer.scss';
import { Link } from 'react-router-dom';

import logo from '../../assets/logo/reborn-logo-w.png';

import {
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaBehanceSquare,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="container">
          <div className="top">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="text">
              <h1>
                <span>Agency, </span>
                <br />
                but nothing <br /> like it.
              </h1>
            </div>
          </div>
          <div className="bottom">
            <div className="info">
              <div className="address">
                <div className="content">
                  <h2>Headquarters</h2>
                  <p>Wespa Business & Lounge</p>
                </div>
                <div className="content">
                  <p>Radnička cesta 52</p>
                  <p>10 000 Zagreb</p>
                </div>
              </div>
              <div className="contact">
                <div className="content">
                  <h2>Write to us</h2>
                  <p>
                    <a href="mailto:hello@rbrn.hr">hello@rbrn.hr</a>
                  </p>
                </div>
                <div className="content">
                  <h2>Call us</h2>
                  <p>
                    <a href="tel:+385953700141">095 3700 141</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="socials">
              <a href="https://www.facebook.com/people/Reb%C3%B8rn-Agency/100086659041412/">
                <div className="icon-bg">
                  <FaFacebookF />
                </div>
              </a>
              <a href="https://www.instagram.com/rbrn.hr/">
                <FaInstagram />
              </a>
              <a href="https://www.linkedin.com/company/rebornagency">
                <div className="icon-bg">
                  <FaLinkedinIn />
                </div>
              </a>
              <a href="https://www.behance.net/ivanbioi1/">
                <div className="icon-bg">
                  <FaBehanceSquare />
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="row">
            <p>&#169; 2023 Reborn Communications Ltd.</p>
            <span>|</span>
            <p>
              Developed by <a href="//www.livens.co">livens</a>
            </p>
          </div>
          <div className="row">
            <Link to="privacy">Privacy</Link>
            <span>|</span>
            <Link to="terms-of-use">Terms of Use</Link>
            {/* <span>|</span>
            <Link to="support">Support</Link> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
