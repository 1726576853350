import './privacyPage.scss';
import { Helmet } from 'react-helmet';

const PrivacyPage = () => {
  return (
    <>
      <Helmet>
        <title>Privacy | Reborn Agency</title>
      </Helmet>
      <div className="privacyPage">
        <h1>Privacy Policy</h1>
        <h3>Last updated on January 28, 2023</h3>
        <p>
          At Reborn Communications Ltd., we are committed to protecting the
          privacy and security of our customers, clients, and website visitors.
          This Privacy Policy explains how we collect, use, disclose, and
          protect the information we receive from you when you visit our website
          https://rbrn.hr. We are committed to complying with the General Data
          Protection Regulation (GDPR) and other relevant data protection
          legislation. We want to ensure that the personal information you
          provide when using our website https://rbrn.hr is kept secure and
          processed per your rights.
        </p>
        <p>
          We may collect personal information such as your name, email address,
          and other contact information. We may also collect and process
          information about your visit, such as your IP address (a number used
          to identify your computer or other device connected to the internet),
          the pages you view, the date and time of your visit, and additional
          information about how you interacted with the website. This
          information is used to provide an improved user experience, analyse
          the website's performance, and help us improve our services.
        </p>
        <p>
          This information is used to improve our website and to provide
          services such as newsletters and other communications. We may also use
          your information for marketing purposes, such as sending you
          information about our products and services.
        </p>
        <p>
          We take all reasonable steps to protect your personal information from
          misuse, unauthorised access, or disclosure. For example, we use
          encryption to protect your data and store it securely. However, we may
          disclose your personal information to third parties to provide
          services or products you have requested, comply with legal
          requirements, or protect our rights.
        </p>
        <p>
          We use cookies to improve your experience on our website. Cookies are
          small pieces of data stored on your computer. We may also use
          third-party cookies to track website usage. We also use cookies and
          other tracking technologies to store certain information.
        </p>
        <p>
          We may change this Privacy Policy from time to time. The updated
          version of the policy will be posted on our website. If you have any
          questions or concerns about our Privacy Policy, GDPR compliance, or
          how we use your data, don't hesitate to contact us at hello@rbrn.hr.
        </p>
      </div>
    </>
  );
};

export default PrivacyPage;
