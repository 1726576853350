import "./latest.scss";
import { Link } from "react-router-dom";

const Latest = () => {
  return (
    <div className="latest-container">
      <div className="container">
        <div className="main-article">
          <Link to="/latest/royal-communications" className="overlay">
            <div className="subtitles">
              <h5>January 28, 2023</h5>
              <h5>Blog</h5>
            </div>
            <h2>
              How Royal Communications (should) handle Prince Harry's new book?
            </h2>
          </Link>
          <div className="article-image">
            <img
              src="https://reborn-s3.s3.eu-central-1.amazonaws.com/article2_fee8f58d2a.webp"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="title">
          <div className="dots">
            <div className="full"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
          <div className="categories">
            <p>Work</p>
            <span>|</span>
            <p>Events</p>
            <span>|</span>
            <p>News</p>
            <span>|</span>
            <p>Blog</p>
          </div>
          <h1>Latest</h1>
        </div>

        <div className="article">
          <div className="article-image">
            <img
              src="https://reborn-s3.s3.eu-central-1.amazonaws.com/article31_28f938dd24.webp"
              alt=""
            />
          </div>
          <Link to="/latest/we-found-our-place" className="overlay">
            <div className="subtitles">
              <h5>January 22nd, 2023</h5>
              <h5>Blog</h5>
            </div>
            <h2>We found our place: it's called Wespa, and it rocks</h2>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Latest;
