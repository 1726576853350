import { useState } from 'react';
import './contactPage.scss';
import '../../components/SubscriptionForm/subscriptionForm.scss';

import { BsFillTelephoneFill } from 'react-icons/bs';
import { IoMail, IoLocationSharp } from 'react-icons/io5';
import {
  FaBehanceSquare,
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
} from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ContactPage = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    phone: '',
    email: '',
    message: '',
  });

  //disable button if empty or set as minlime contact form

  const sendEmail = (e) => {
    // e.preventDefault();

    emailjs
      .send(
        'service_xc1pltf',
        'template_e54r6t4',
        formData,
        '-97qZpLCclc4kqKhm'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    navigate('/success', { replace: true });
  };

  return (
    <>
      <Helmet>
        <title>Contact | Reborn Agency</title>
      </Helmet>
      <div className="contactPage">
        <div className="contactPage-form">
          <h1 className="title-s">
            Get in <span>touch</span>{' '}
          </h1>
          <form>
            <div className="form-row">
              <div className="input-box">
                <input
                  type="text"
                  name="firstName"
                  required
                  value={formData.firstName}
                  onChange={(e) =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                />
                <span className="input-title">First name</span>
                <i></i>
              </div>
              <div className="input-box">
                <input
                  type="text"
                  name="lastName"
                  required
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                />
                <span className="input-title">Last name</span>
                <i></i>
              </div>
            </div>

            <div className="form-row">
              <div className="input-box">
                <input
                  type="text"
                  name="company"
                  required
                  value={formData.company}
                  onChange={(e) =>
                    setFormData({ ...formData, company: e.target.value })
                  }
                />
                <span className="input-title">Company</span>
                <i></i>
              </div>

              <div className="input-box">
                <input
                  type="phone"
                  name="phone"
                  required
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                />
                <span className="input-title">Phone</span>
                <i></i>
              </div> 
            </div>
            <div className="form-row">
              <div className="input-box" style={{ width: '100%' }}>
                <input
                  type="text"
                  name="email"
                  required
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
                <span className="input-title">Email</span>
                <i></i>
              </div>
            </div>
            <div className="form-row">
              <div className="input-box" style={{ width: '100%' }}>
                <textarea
                  type="text"
                  name="message"
                  required
                  rows="3"
                  value={formData.message}
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                />
                <span className="input-title">Message</span>
                <i></i>
              </div>
            </div>
            <div className="form-row">
              <button onClick={() => sendEmail()}>Send message</button>
            </div>
          </form>
        </div>
        <div className="contactPage-info">
          <h1>Get in touch</h1>
          <ul>
            <li className="contactPage-contactInfo">
              <BsFillTelephoneFill />{' '}
              <a href="tel:+385953700141">095 3700 141</a>
            </li>
            <li className="contactPage-contactInfo">
              <IoMail /> <a href="mailto:hello@rbrn.hr">hello@rbrn.hr</a>
            </li>
            <li className="contactPage-contactInfo">
              <IoLocationSharp />
              <ul>
                <li>Wespa Business & Lounge</li>
                <li>Radnička cesta 52</li>
                <li>10 000 Zagreb</li>
              </ul>
            </li>
          </ul>
          <div className="contactPage-socials">
            <a href="https://www.facebook.com/people/Reb%C3%B8rn-Agency/100086659041412/">
              <FaFacebookSquare />
            </a>
            <a href="https://www.instagram.com/rbrn.hr/">
              <FaInstagramSquare />
            </a>
            <a href="https://www.linkedin.com/company/rebornagency">
              <FaLinkedin />
            </a>
            <a href="https://www.behance.net/ivanbioi1/">
              <FaBehanceSquare />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
