import './instagramFeed.scss';

import {BsInstagram} from 'react-icons/bs'

import feed1 from '../../assets/feed/feed1.jpg'
import feed2 from '../../assets/feed/feed2.jpg'
import feed3 from '../../assets/feed/feed3.jpg'
import feed4 from '../../assets/feed/feed4.jpg'
import { Link } from 'react-router-dom';

const InstagramFeed = () => {
  return (
    <div className="if-container">
      <div className="container">
        <div className="container-lg">
          <img src={feed1} alt="ig-feed" />
        </div>
      </div>
      <div className="container grid">
        <div className="container-sm">
          <img src={feed2} alt="ig-feed" />
        </div>
        <div className="container-sm">
          <img src={feed3} alt="" />
        </div>
        <div className="container-sm">
          <img src={feed4} alt="" />
        </div>
        <a href='https://www.instagram.com/rbrn.hr/' className="container-sm">
          <BsInstagram/>
        </a>
      </div>
    </div>
  );
};

export default InstagramFeed;
