import './newsletter.scss';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';

const Newsletter = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_xc1pltf',
        'template_fu10bfa',
        form.current,
        '-97qZpLCclc4kqKhm'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    alert('Thank you for subscribing to our newsletter! We promise not to spam you.');
    e.target.reset();
  };
  return (
    <div className="newsletter-container">
      <div className="title">
        <h1>
          News<span>letter</span>
        </h1>
      </div>
      <div className="form">
        <form ref={form} onSubmit={sendEmail}>
          <div className="form">
            <input type="email" placeholder="your email address" required />

            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Newsletter;
