import { useRoutes } from 'react-router-dom';

import MainLayout from '../layouts/MainLayout';
import HomePage from '../pages/HomePage/HomePage';
import WhatWeDoPage from '../pages/WhatWeDoPage/WhatWeDoPage';
import OurStoryPage from '../pages/OurStoryPage/OurStoryPage';
import ClientsProjectsPage from '../pages/ClientsProjectsPage/ClientsProjectsPage';
import SubscriptionModelPage from '../pages/SubscriptionModelPage/SubscriptionModelPage';
import PeoplePage from '../pages/PeoplePage/PeoplePage';
import ContactPage from '../pages/ContactPage/ContactPage';
import SubscriptionPolicyPage from '../pages/SubscriptionPolicyPage/SubscriptionPolicyPage';
import ImpressumPage from '../pages/ImpressumPage/ImpressumPage';
import TermsOfUsePage from '../pages/TermsOfUsePage/TermsOfUsePage';
import PrivacyPage from '../pages/PrivacyPage/PrivacyPage';
import LatestPage from '../pages/LatestPage/LatestPage';

import ArticlePage from '../pages/ArticlePage/ArticlePage';
import SuccessPage from '../pages/SuccessPage/SuccessPage';
import Page404 from '../pages/Page404/Page404';
import Article1 from '../pages/Articles/Article1';
import Article2 from '../pages/Articles/Article2';
import Article3 from '../pages/Articles/Article3';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { index: true, element: <HomePage /> },
        { path: 'what-we-do', element: <WhatWeDoPage /> },
        { path: 'our-story', element: <OurStoryPage /> },
        { path: 'clients-and-projects', element: <ClientsProjectsPage /> },
        // sub pages
        {
          path: 'latest',
          children: [
            { element: <LatestPage />, index: true },
            { path: 'take-off-with-reborn', element: <Article1 /> },
            { path: 'we-found-our-place', element: <Article2 /> },
            { path: 'royal-communications', element: <Article3 /> },

            // { path: ':slug', element: <ArticlePage /> },
          ],
        },
        { path: 'subscription', element: <SubscriptionModelPage /> },
        { path: 'people', element: <PeoplePage /> },
        { path: 'contact', element: <ContactPage /> },
        { path: 'privacy', element: <PrivacyPage /> },
        { path: 'terms-of-use', element: <TermsOfUsePage /> },
        // {path: 'support', element: <SupportPage />},
        // { path: 'subscription-policy', element: <SubscriptionPolicyPage /> },
        // { path: 'impressum', element: <ImpressumPage /> },
        { path: 'success', element: <SuccessPage /> },
        {
          path: '*',
          element: <Page404 />,
        },
      ],
    },
  ]);
}
