import './homePage.scss';

import video from '../../assets/0d3bdf91b47f001fb75e19ca28a8f217-sm.mp4';
import logoTekst from '../../assets/logo/reborn-logo-w-tekst.png';
import WhatWeDo from '../../components/WhatWeDo/WhatWeDo';
import OurStory from '../../components/OurStory/OurStory';
import Latest from '../../components/Latest/Latest';
import Clients from '../../components/Clients/Clients';
import Allies from '../../components/Allies/Allies';
import Newsletter from '../../components/Newsletter/Newsletter';
import InstagramFeed from '../../components/InstagramFeed/InstagramFeed';
import People from '../../components/People/People';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { motion, AnimatePresence } from 'framer-motion';
import Modal from '../../components/Modal/Modal';

const HomePage = () => {
  const [offset, setOffset] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Reborn Agency</title>
      </Helmet>
      <header>
        <div
          className="parallax"
          style={{
            transform: `translateY(${offset * 0.6}px)`,
          }}
        >
          <div className="video-overlay"></div>
          <video autoPlay muted loop src={video} playsInline>
            <source src={video} type="video/mp4" />
          </video>

          <div className="text">
            <img src={logoTekst} alt="logo" />

            <h3>Agency, but nothing like it.</h3>
          </div>
        </div>
        <div
          className="parallax-bg"
          style={{
            opacity: `${(offset * 0.1) / 60}`,
          }}
        ></div>
      </header>

      <WhatWeDo />

      <section>
        <OurStory />
      </section>

      <section>
        <Latest />
      </section>

      <section>
        <Clients />
      </section>

      <section>
        <People />
      </section>

      <section>
        <Allies />
      </section>

      <section>
        <Newsletter />
      </section>

      <section>
        <InstagramFeed />
      </section>

      {/* <section>
        <h1>TEST</h1>
        <button onClick={() => (modalOpen ? close() : open())}>
          Launch modal
        </button>

        <AnimatePresence
          initial={false}
          wait={true}
          onExitComplete={() => null}
        >
          {modalOpen && <Modal modalOpen={modalOpen} handleClose={close} />}
        </AnimatePresence>
      </section> */}
    </>
  );
};

export default HomePage;
