import { IoClose } from 'react-icons/io5';
import { BsPlusLg } from 'react-icons/bs';
import './popUpPackages.scss';

const PopUpPackageBasic = ({ closeModal }) => {
  return (
    <div className="popup-modal-packages" onClick={() => closeModal()}>
      <div
        className="popup-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button onClick={() => closeModal()} className="popup-close-btn">
          <IoClose />
        </button>

        <div className="popup-content">
          <h2>Basic package</h2>
          <p>from 540 euros</p>
          <div className="text">
            <ul>
              <li>
                <span>1</span> Social Media Management & set-up (Facebook/LinkedIn/Instagram - 2 social media channels, up to 8 posts & 2 story packages)
              </li>
              <li>
                <span>2</span> Basic Marketing & Communications support (1 newsletter or two blog posts)
              </li>
              <li>
                <span>3</span>Monthly reports
              </li>
            </ul>
          </div>
          <div className="text">
            <h3>You can combine one or more of these with your model:</h3>
            <ul>
              <li><BsPlusLg/> add custom business website development & hosting (up to 2 subpages) - from 349 euros</li>
              <li><BsPlusLg/> add logo design (up to 3 proposals) - from 499 euros</li>
              <li><BsPlusLg/> communications consulting (up to 3 hours) - from 499 euros</li>
              <li><BsPlusLg/> TikTok for Business - custom price</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpPackageBasic;
