import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: 'https://sea-lion-app-hhd7d.ondigitalocean.app/graphql'
});

const client = new ApolloClient({
  cache,
  link
});

export default client;
