import "./latestArticles.scss";

import { Link } from "react-router-dom";

const LatestArticles = () => {


  return (
    <div className="latestArticles-container">
      <div className="latestPage-articles">
        <>
          <div className="article">
            <div className="articleImage">
              <img
                src="https://reborn-s3.s3.eu-central-1.amazonaws.com/article2_fee8f58d2a.webp"
                alt=""
              />
            </div>
            <Link to="/latest/royal-communications" className="articleContent">
              <div className="articleHeader">
                <h5>January 24th, 2023</h5> <span>|</span>
                <h5>Blog</h5>
              </div>
              <h2 className="articleText">
                How Royal Communications (should) handle Prince Harry's new
                book?
              </h2>
            </Link>
          </div>

          <div className="article">
            <div className="articleImage">
              <img
                src="https://reborn-s3.s3.eu-central-1.amazonaws.com/article31_28f938dd24.webp"
                alt=""
              />
            </div>
            <Link to="/latest/we-found-our-place" className="articleContent">
              <div className="articleHeader">
                <h5>January 22nd, 2023</h5> <span>|</span>
                <h5>Blog</h5>
              </div>
              <h2 className="articleText">
                We found our place: it's called Wespa, and it rocks
              </h2>
            </Link>
          </div>

          <div className="article">
            <div className="articleImage">
              <img
                src="https://reborn-s3.s3.eu-central-1.amazonaws.com/article1_84413c98e0.webp"
                alt=""
              />
            </div>
            <Link to="/latest/take-off-with-reborn" className="articleContent">
              <div className="articleHeader">
                <h5>January 24th, 2023</h5> <span>|</span>
                <h5>Blog</h5>
              </div>
              <h2 className="articleText">
                Take off with reborn: how brands start to fell in love (with us)
              </h2>
            </Link>
          </div>
        </>
      </div>
    </div>
  );
};

export default LatestArticles;
