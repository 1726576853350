import './successPage.scss'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {BsCheck} from 'react-icons/bs'

const SuccessPage = () => {
  return (
    <>
      <Helmet>
        <title>Success | Reborn Agency</title>
      </Helmet>
      <div className='successPage'>
        <div className="icon">
          <BsCheck/>
        </div>
        <h1 className='successPage-h1'>Thank you for contacting us.</h1>
        <p className='successPage-p'>You are very important to us, all information received will always remain confidential. We will contact you as soon as we review your message.</p>
        <Link to="/" className='successPage-btn'>Home</Link>
      </div>
    </>
  );
};

export default SuccessPage;
