import './ourStory.scss';

import bg1 from '../../assets/images/bg1.jpeg';
import bg2 from '../../assets/images/bg2.jpeg';
import bg3 from '../../assets/images/bg3.jpeg';
import bg4 from '../../assets/images/bg4.jpeg';
import bg5 from '../../assets/images/bg5.jpeg';
import bg6 from '../../assets/images/bg6.jpeg';
import bg7 from '../../assets/images/bg7.jpeg';
import bg8 from '../../assets/images/bg8.jpeg';

const OurStory = () => {
  return (
    <>
      <div className="os-container">
        <div className="background">
          <div className="img-container one">
            <img src={bg2} alt="bg" />
          </div>
          <div className="img-container two overlay">
            <img src={bg7} alt="bg" />
          </div>
          <div className="img-container three overlay">
            <img src={bg5} alt="bg" />
          </div>
          <div className="img-container four ">
            <img src={bg6} alt="bg" />
          </div>
          <div className="img-container five overlay">
            <img src={bg4} alt="bg" />
          </div>
          <div className="img-container six overlay">
            <img src={bg3} alt="bg" />
          </div>
          <div className="img-container seven overlay">
            <img src={bg1} alt="bg" />
          </div>
          <div className="img-container eight">
            <img src={bg8} alt="bg" />
          </div>
        </div>
        <div className="text">
          <p>
            We partner with various brands & businesses that want to make an
            impact on their growth, on the topic, on the public or their
            stakeholders. With us, experience comes as first.
          </p>
        </div>
        <div className="title">
          <h1>
            Our <br /> Story
          </h1>
        </div>
      </div>
    </>
  );
};

export default OurStory;
