import { Link } from 'react-router-dom'
import '../SuccessPage/successPage.scss'

const Page404 = () => {
  return (
    <div className='successPage'>
      <div className='page404-title'>

      <h1 className='page404-h1'>F*ck!</h1>
      <h2 className='page404-h2'>404 error</h2>
      </div>
      <p className='page404-p'>We hate it as much as you do. <br />
      Try something else.</p>
      <Link to="/" className='successPage-btn'>Home</Link>
    </div>
  )
}

export default Page404