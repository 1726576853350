import './clients.scss';

import wespaLogo from '../../assets/clients/wespa-logo.png';
import grabAhome from '../../assets/clients/grabAhome.png';
import nielsen from '../../assets/clients/nielsen.png';
import oazaMira from '../../assets/clients/oazaMira.png';
import missturism from '../../assets/clients/miss-tourism_logo.png';
import tzzz from '../../assets/clients/tzzz.png';
import gruntek from '../../assets/clients/Logo_gruntek.png'
import valamar from '../../assets/clients/valamar.png'
import medimurje from '../../assets/clients/Visit_Medimurje_logo.png'
import tourismLab from '../../assets/clients/Tourism_Lab_logo.png'

const Clients = () => {
  return (
    <div className="clients-container">
      <div className="clients-grid">
        <div className="text">
          <h2>We love(d) working with them.</h2>
        </div>
        <div className="title">
          <h1>Clients</h1>
        </div>
        <div className="logo l1">
          <img src={gruntek} alt="gruntek" />
        </div>
        <div className="logo l2">
          <img src={medimurje} alt="nielsen" />
        </div>
        <div className="logo l3">
          <img src={tourismLab} alt="oazaMira" />
        </div>
        <div className="logo l4">
          <img src={tzzz} alt="oazaMira" />
        </div>
        <div className="logo l5">
          <img src={valamar} alt="Valamar" />
        </div>
        <div className="logo l6">
          <img src={grabAhome} alt="grabAhome" />
        </div>
        <div className="logo l7">
          <img src={missturism} alt="wespa" />
        </div>
        <div className="logo l8">
          <img src={oazaMira} alt="oazaMira" />
        </div>
        <div className="logo l9">
          <img src={nielsen} alt="nielsen" />
        </div>
      </div>
    </div>
  );
};

export default Clients;
