import '../ArticlePage/articlePage.scss';

import { Link } from 'react-router-dom';

import { IoIosArrowBack } from 'react-icons/io';
import LatestArticles from '../../components/LatestArticles/LatestArticles';
import { Helmet } from 'react-helmet';



const Article3 = () => {
 
  return (
    <>
      <Helmet>
        <title>How Royal Communications (should) handle Prince Harry's new book? | Reborn Agency</title>
      </Helmet>
      <div className="articlePage">
        <div className="articlePage-navigation">
          <Link to="/latest" className="back-button">
            <IoIosArrowBack /> <p>go back to latest</p>
          </Link>
        </div>
        <div className="articlePage-header">
          <div className="header-image">
            <img src='https://reborn-s3.s3.eu-central-1.amazonaws.com/article2_fee8f58d2a.webp' alt="" />
          </div>
          <div className="header-title">
            <h1>How Royal Communications (should) handle Prince Harry's new book?</h1>
            <div className="header-subtitle">
              <h5>January 28, 2023</h5>
              <span>|</span>
              <h5>
                Blog
              </h5>
            </div>
          </div>
        </div>
        <div className="articlePage-content">
         <p>The question that is asking itself is, do the Royals need a new communications strategy? Is the time up for "do not comment"? Prince Harry's book is a new bestseller just after Queen Elizabeth II's death has shaken the British Monarchy and the Commonwealth, after which Royal brand strength is seriously vulnerable.</p>
         <h3>Trust in the Institution</h3>
         <p>A majority of British people (56%) felt that the Institution of the monarchy is good for Britain, said YouGov in the summer of 2022, while at the end of 2019, less than half of Britons (44%) said they trusted the Institution. They are considering numbers. Should Royals still stick to the "do not comment" strategy, or is it time for a new direction? Edelman 2020 Trust Barometer showed that Russia is the only less trusting society than Britain. While 3 of 5 Britons said, they are losing faith in democracy "as an effective form of government." But, of course, Royal drama is not enough, so there's a need to be a change at 10 Downing street every so and on. Right?</p>
         <img src="https://reborn-s3.s3.eu-central-1.amazonaws.com/article22_bb5217ffb4.webp" alt="" />
         <h3>Queen Elizabeth II's reputation held the Institution strong</h3>
         <p>Late Queen Elizabeth II, 81% of people held in positive regard showed research of The Guardian, and it was the only positive constant for a decade survey said. June 2018 survey shows that Britons are more proud of NHS (health service), national history, and armed forces than the royal family. If we talk about the ratio of older (65+) and younger people and their pride in the Institution, it goes 43 - 20. Are royals too traditional for a new generation to grab their attention?</p>
         <img src="https://reborn-s3.s3.eu-central-1.amazonaws.com/article23_0f9f8a8e1d.webp" alt="" />
         <p>Moreover, the Royals have had one consistent problem on the trail of those above for several years. As young people are not so interested in meeting the everyday life of the Royals working for the Institution, they seek more interesting content where they will find more information about them. One of the ways has been the TV show drama "The Crown," aired on Netflix, breaking records and hitting media headlines. "It's made monarchy into an entertainment," BBC cites one of the historians.  Remember, if you don't answer crucial questions yourself, people will answer them themselves. Why and how? It's important to provide thoughtful, accurate, and informative answers to questions related to your business or product. Providing accurate and up-to-date information can help build trust.</p>
         <h3>Anti-royal campaign: from Oprah's interview to the memoirs</h3>
         <p>As more side stories are coming out about Prince Harry's memoir, they crush into a timeline of the biggest weekend for His Majesty The King - the coronation ceremony and following events. Will the coronation events be marked with memoirs tint, or the next most watched Royal event in history? Maybe both? Probably both.</p>
         <img src="https://reborn-s3.s3.eu-central-1.amazonaws.com/article24_8e98d9f463.webp" alt="" />
         <p>The 2021 television special "Oprah with Meghan and Harry" was a sign that the couple has decided on a "tell all" strategy. Since Oprah's special, they have given a few more extensive interviews and revealed a Netflix documentary about their leaving an Institution. On top of that comes Harry's memoir, which has damaged the shaky royal reputation more.  We yet do not know what is next to come, but is keeping silent the best that Royals could do?</p>
        </div>
        <div className="articlePage-footer">
          <div className="articlePage-footer-btn">
            <Link to="/latest">Latest from reborn</Link>
          </div>
          <LatestArticles />
        </div>
      </div>
    </>
  );
};

export default Article3;
