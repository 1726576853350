import { useState } from 'react';
import './navbar.scss';
import logo from '../../assets/logo/reborn-logo-w.png';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <nav>
        <div className="navbar">
          <div className="circle">
            <div className="menu-btn" onClick={() => setOpenModal(!openModal)}>
              <div className="line" style={{ width: '70%' }}></div>
              <div className="line" style={{ width: '85%' }}></div>
              <div className="line" style={{ width: '100%' }}></div>
            </div>
          </div>
        </div>
        <div
          className="menu-overlay"
          style={{
            left: openModal ? '0' : '-100%',
            transitionDelay: openModal ? '0s' : '0.55s',
          }}
        >
          <ul className="menu-links">
            <li className="menu-item">
              <NavLink
                to="/"
                onClick={() => setOpenModal(!openModal).window.scrollTo(0, 0)}
                style={{
                  top: openModal ? '0' : '100px',
                  transitionDelay: openModal ? '0.7s' : '0s',
                }}
              >
                <span>Home</span>
              </NavLink>
              <div className="menu-item-wrapper"></div>
            </li>
            <li className="menu-item">
              <NavLink
                to="what-we-do"
                onClick={() => setOpenModal(!openModal).window.scrollTo(0, 0)}
                style={{
                  top: openModal ? '0' : '100px',
                  transitionDelay: openModal ? '0.8s' : '0s',
                }}
              >
                <span>What we do?</span>
              </NavLink>
              <div className="menu-item-wrapper"></div>
            </li>
            <li className="menu-item">
              <NavLink
                to="our-story"
                onClick={() => setOpenModal(!openModal).window.scrollTo(0, 0)}
                style={{
                  top: openModal ? '0' : '100px',
                  transitionDelay: openModal ? '0.9s' : '0s',
                }}
              >
                <span>Our Story</span>
              </NavLink>
              <div className="menu-item-wrapper"></div>
            </li>
            <li className="menu-item">
              <NavLink
                to="subscription"
                onClick={() => setOpenModal(!openModal).window.scrollTo(0, 0)}
                style={{
                  top: openModal ? '0' : '100px',
                  transitionDelay: openModal ? '1s' : '0s',
                }}
              >
                <span>
                  Subscription model<p className="label">soon</p>{' '}
                </span>
              </NavLink>
              <div className="menu-item-wrapper"></div>
            </li>
            <li className="menu-item">
              <NavLink
                to="people"
                onClick={() => setOpenModal(!openModal).window.scrollTo(0, 0)}
                style={{
                  top: openModal ? '0' : '100px',
                  transitionDelay: openModal ? '1.1s' : '0s',
                }}
              >
                <span>People & Culture</span>
              </NavLink>
              <div className="menu-item-wrapper"></div>
            </li>
            <li className="menu-item">
              <NavLink
                to="latest"
                onClick={() => setOpenModal(!openModal).window.scrollTo(0, 0)}
                style={{
                  top: openModal ? '0' : '100px',
                  transitionDelay: openModal ? '1.2s' : '0s',
                }}
              >
                {' '}
                <span>Latest</span>
              </NavLink>
              <div className="menu-item-wrapper"></div>
            </li>
            <li className="menu-item">
              <NavLink
                to="contact"
                onClick={() => setOpenModal(!openModal).window.scrollTo(0, 0)}
                style={{
                  top: openModal ? '0' : '100px',
                  transitionDelay: openModal ? '1.3s' : '0s',
                }}
              >
                <span>Contact</span>
              </NavLink>
              <div className="menu-item-wrapper"></div>
            </li>
          </ul>

          <div className="inner-container">
            <img src={logo} alt="logo" />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
