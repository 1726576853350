import { IoClose } from 'react-icons/io5';
import './popUpWWD.scss';

const popups = [
  {
    id: 1,
    title: 'Public Relations',
    text: 'Public relations involves so much more than just sending out a press release. It encompasses creating a positive relationship between an organisation and its public, including the media, customers, employees, investors, and other stakeholders.',
  },
  {
    id: 2,
    title: 'Marketing Communications',
    text: 'Creating engaging and creative campaigns or content to help you reach your target audience is one of our specialties. We have experience developing content for social media, email campaigns, and web pages to ensure that the right people see your message.',
  },
  {
    id: 3,
    title: 'Digital Strategy, Social & Content',
    text: "We have extensive experience helping our clients create effective digital strategies that maximise their reach, engage their target audiences, and drive conversions. All that comes with developing social media campaigns and influencer marketing strategies tailored to clients' goals.",
  },
  {
    id: 4,
    title: 'Creative solutions & Branding',
    text: 'We make creative and innovative branding solutions tailored to your unique needs and budget with the best design partners. We can discuss brand identity for a new brand, rebranding, positioning, and many more.',
  },

  {
    id: 5,
    title: 'Events & Production',
    text: "From planning to execution, we're masters in creating a successful event that your guests will remember. Online, hybrid, or offline - you name it. We're here to help you design the perfect program, choose the right venue, create a stylish décor, and find the best entertainment options.",
  },
  {
    id: 6,
    title: 'Video & Photo',
    text: 'With our partners, we offer top-notch video & photo services to help you capture any moment you want to remember. Our external team of experienced professionals will work with you to get the best possible results.',
  },
  {
    id: 7,
    title: 'Digital solutions, web & apps',
    text: "In partnership with experienced developers from several agencies specializing in creating interactive and user-friendly digital solutions, we will make a website and app tailored to clients' needs.",
  },
];

const PopUpWWD = ({ closeModal, index }) => {
  return (
    <div className="popup-modal" onClick={() => closeModal()}>
      <div
        className="popup-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button onClick={() => closeModal()} className="popup-close-btn mobile-btn">
          <IoClose />
        </button>

        <div className="popup-content">
          <h2>{popups[index].title}</h2>
          <p>{popups[index].text}</p>
        </div>
      </div>
    </div>
  );
};

export default PopUpWWD;
