import '../PrivacyPage/privacyPage.scss';
import { Helmet } from 'react-helmet';

const TermsOfUsePage = () => {
  return (
    <>
      <Helmet>
        <title>Terms of Use | Reborn Agency</title>
      </Helmet>
      <div className="privacyPage">
        <h1>Terms of Use</h1>
        <h3>Last updated on January 28, 2023</h3>
        <p>
          Welcome to https://rbrn.hr (the "Website"), operated by Reborn
          Communications Ltd. ("Reborn," "we," "us"), located in the Republic of
          Croatia. Please read these Terms of Use ("Terms") before using the
          Website. By using the Website, you agree to these Terms. If you do not
          agree to these Terms, you must not use the Website.
        </p>
        <p>
          These Terms apply to all users of the Website, including users who are
          also contributors of content, information, and other materials or
          services on the Website.
        </p>
        <p>
          We reserve the right at any time to modify, suspend or discontinue the
          Website or any part of it with or without notice. You agree that we
          will not be liable to you or any third party for any modification,
          suspension, or discontinuation of the Website or any part of it.
        </p>
        <p>
          We may change these Terms from time to time. Therefore, please review
          these Terms periodically for changes. If you do not agree to any
          changes to these Terms, you must stop using the Website. Your
          continued use of the Website after we make changes is deemed to be
          acceptance of those changes, so please check these Terms periodically
          for updates.
        </p>
        <p>
          You must be at least 18 years of age to use the Website. Therefore, by
          using the Website and agreeing to these Terms, you represent and
          warrant that you are at least 18 years of age.
        </p>
        <p>
          The Website and its content are intended solely for your personal or
          business use (if you're planning to or working with us). You may not
          use the Website or its content for commercial or other purposes not
          permitted by these Terms.
        </p>
        <p>
          We may provide links to other websites or resources. We have no
          control over these websites and resources, and we are not responsible
          for the availability of these websites or resources. We do not endorse
          and are not responsible or liable for any content, advertising,
          products, or other materials on or available from these websites or
          resources. You agree that we will not be responsible or liable,
          directly or indirectly, for any damage or loss caused or alleged to be
          caused by or in doing a thing written on the Website.
        </p>
        <p >
          Reborn Communications Ltd. I Company ID number: 05883576218 I VAT ID:
          HR05883576218 I Commercial Court in Zagreb I Equity capital: 2.654,46
          euro ( HRK 20,000.00) paid in total I Member of the Board: Ivan Biošić
          I Erste & Steiermärkische Bank I IBAN: HR6424020061100746631 I
        </p>
      </div>
    </>
  );
};

export default TermsOfUsePage;
