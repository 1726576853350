import './allies.scss';

import mintLime from '../../assets/partners/mint-lime-logo.png';
import redLight from '../../assets/partners/allies-red.png';
import d4v from '../../assets/partners/D4V-Logo.png';
import mediahint from '../../assets/partners/mediahint_transp_white_50.png';

const Allies = () => {
  return (
    <div className="allies-container">
      <div className="content-container">
        <div className="logo ">
          <img src={mintLime} alt="mintlime" />
        </div>
        <div className="logo ">
          <img src={redLight} alt="redlight" />
        </div>
        <div className="logo">
          <img src={d4v} alt="division4vision" className='logo-padding' />
        </div>
        <div className="logo">
          <img src={mediahint} alt="mediahint"  className='logo-padding'/>
        </div>
      </div>
      <div className="title">
        <h1>Partners</h1>
      </div>
    </div>
  );
};

export default Allies;
