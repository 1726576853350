import { useState } from 'react';
import { IoClose, IoContractOutline } from 'react-icons/io5';
import emailjs from '@emailjs/browser';

import './subscriptionForm.scss';

const SubscriptionForm = ({ closeModal }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    phone: '',
    email: '',
    package: '',
  });

  const sendEmail = (e) => {
    emailjs
      .send(
        'service_xc1pltf',
        'template_i1em7lp',
        formData,
        '-97qZpLCclc4kqKhm'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    alert("Thank you for your subscription! We'll contact you shortly.");
  };

  return (
    <div className="subscription-form" onClick={() => closeModal()}>
      <div
        className="popup-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button onClick={() => closeModal()} className="popup-close-btn">
          <IoClose />
        </button>

        <div className="popup-content">
          {/* <h1>Subscribe</h1> */}
          <form>
            <div className="form-row">
              <div className="input-box">
                <input
                  type="text"
                  name="firstName"
                  required
                  value={formData.firstName}
                  onChange={(e) =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                />
                <span className="input-title">First name</span>
                <i></i>
              </div>

              <div className="input-box">
                <input
                  type="text"
                  name="lastName"
                  required
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                />
                <span className="input-title">Last name</span>
                <i></i>
              </div>
            </div>
            <div className="form-row">
              <div className="input-box">
                <input
                  type="text"
                  name="company"
                  required
                  value={formData.company}
                  onChange={(e) =>
                    setFormData({ ...formData, company: e.target.value })
                  }
                />
                <span className="input-title">Company</span>
                <i></i>
              </div>

              <div className="input-box">
                <input
                  type="phone"
                  name="phone"
                  required
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                />
                <span className="input-title">Phone</span>
                <i></i>
              </div>
            </div>

            <div className="form-row">
              <div className="input-box" style={{ width: '100%' }}>
                <input
                  type="text"
                  name="email"
                  required
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
                <span className="input-title">Email</span>
                <i></i>
              </div>
            </div>

            <div className="form-row">
              <div className="package-row">
                <p>Select package:</p>
              </div>
              <div className="package-inputs">
                <fieldset id="package" className="">
                  <div className="package-row">
                    <input
                      id="basic"
                      type="radio"
                      name="package"
                      value="Basic"
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, package: e.target.value })
                      }
                    />
                    <label htmlFor="basic">
                      <span>Basic</span>
                    </label>

                    <input
                      id="plus"
                      type="radio"
                      name="package"
                      value="Plus"
                      onChange={(e) =>
                        setFormData({ ...formData, package: e.target.value })
                      }
                    />
                    <label htmlFor="plus">
                      <span>Plus</span>
                    </label>
                  </div>
                </fieldset>
              </div>
              <div className="button-container">
                <button onClick={() => sendEmail()}>Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionForm;
